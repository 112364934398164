<template>
  <el-dialog
    id="login-dialog"
    :title="title"
    :visible.sync="loginDialogVisible"
    width="500px"
    v-loading="isLoadingUserData"
    element-loading-text="Loading..."
    element-loading-spinner="el-icon-loading"
    @close="$emit('close')"
  >
    <el-main v-if="userIsGuest">
      <el-form :model="loginForm" ref="loginForm" :rules="loginFormRules">
        <el-form-item prop="username" :error="genericLoginError">
          <el-input placeholder="Email" v-model="loginForm.username"></el-input>
        </el-form-item>
        <el-form-item prop="password" :error="genericLoginError">
          <el-input
            placeholder="Password"
            v-model="loginForm.password"
            type="password"
          ></el-input>
        </el-form-item>
      </el-form>
    </el-main>
    <el-main v-else>
      <ul id="user-list-data">
        <li>
          <i class="el-icon-user-solid"></i>
          {{ user.ragioneSociale }}
        </li>
        <li>
          <i class="el-icon-message"></i>
          {{ user.email }}
        </li>
        <li>
          <i class="el-icon-office-building"></i>
          {{ user.street }}, {{ user.city }}
        </li>
        <!-- <li>
          <b>P.IVA</b>
          : {{ user.partitaIva }}
        </li> -->
      </ul>
    </el-main>
    <div class="login-dialog-footer" slot="footer">
      <el-button-group>
        <el-button type="default" @click="loginDialogVisible = false">{{$t('message.Chiudi').toUpperCase()}}</el-button>
        <el-button v-if="userIsGuest" type="primary" @click="validate()">LOGIN</el-button>
        <el-button v-else type="primary" @click="logout()">LOGOUT</el-button>
      </el-button-group>
    </div>
  </el-dialog>
</template>

<script>
  // import { mapGetters, mapActions, mapState } from "vuex";

  export default {
    props: ["visible"],
    data: function () {
      return {
        // loginDialogVisible: this.visible,
        loginForm: {
          username: "",
          password: "",
        },
        loginFormRules: {
          username: [
            {
              required: true,
              message: "Il codice cliente è obbligatorio",
              trigger: "blur",
            },
          ],
          password: [
            {
              required: true,
              message: "La password è obbligatoria",
              trigger: "blur",
            },
          ],
        },
        isLoadingUserData: false,
        genericLoginError: "",
      };
    },
    methods: {
      async validate() {
        if (this.$refs["loginForm"] != undefined) {
          // @ts-ignore
          // await this.$refs["loginForm"].validate((valid: any) => {

          // });
          let valid = await this.$refs["loginForm"].validate();
          if (valid) {
            // eslint-disable-next-line no-unused-vars
            let r = await this.login(
              this.loginForm.username,
              this.loginForm.password
            );
            if (this.isGuest) {
              if (r.data.error) {
                this.genericLoginError = r.data.msg
                  ? r.data.msg
                  : "Username o password errati";
              } else {
                this.genericLoginError = "Username o password errati";
              }

              setTimeout(() => {
                this.genericLoginError = "";
              }, 5000);
            }
          }
        }
      },
      // ...mapActions("user", ["login", "logout"]),
      async login(username, password) {
        let res = await this.$store.dispatch("configurator/user/login", {
          username,
          password,
        });
        return res;
      },
      logout() {
        this.$store.dispatch("configurator/user/logout");
      },
    },
    computed: {
      // ...mapGetters("user", { userIsGuest: "isGuest" }),
      userIsGuest() {
        return this.$store.getters["configurator/user/isGuest"];
      },
      // ...mapState("user", ["user"]),
      user() {
        return this.$store.state.configurator.user.user;
      },
      loginDialogVisible: {
        get: function () {
          return this.visible;
        },
        set: function (value) {
          if (value == false) {
            this.$emit("close");
          }
        },
      },
      title() {
        return this.userIsGuest ? this.$t('message.Inserisci_i_tuoi_dati') : "ACCOUNT";
      },
    },
  };
</script>

<style lang="less" >
  #login-dialog {
    #user-list-data {
      list-style-type: none;
      text-align: left;
      li {
        margin: 8px auto;
      }
    }
    .el-dialog__footer {
      padding: 0;
      .login-dialog-footer {
        .el-button-group {
          min-width: 100%;
          .el-button {
            min-width: 50%;
          }
        }
      }
    }
  }
</style>